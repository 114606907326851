import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { NamishMusic } from './NamishMusic';
import reportWebVitals from './reportWebVitals';
import {CircularProgress, Box} from "@mui/material"
import {CSSTransition} from 'react-transition-group';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, getDoc, getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAreEQ3BbYhzRLusAkFt6Y3zfz7ow9eMyI",
  authDomain: "namishmusic-1385b.firebaseapp.com",
  projectId: "namishmusic-1385b",
  storageBucket: "namishmusic-1385b.appspot.com",
  messagingSenderId: "357498277019",
  appId: "1:357498277019:web:07326cec83823e02600a3e",
  measurementId: "G-PD8TNNPLR6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.style.textAlign = NamishMusic.CenteredText;
root.render(
   <React.StrictMode>
    <h1 style={{color:NamishMusic.Black}}>NamishMusic</h1>
   <img src='icon.png' width={350} height={350} style={{borderRadius:"15px"}}></img>
   <p>Relax and listen musics on the go with NamishMusic. Unlike other music softwares, NamishMusic is completely free of charge and Advertisements.</p>
   <form onSubmit={function(e) {
      e.preventDefault();
      startUserExp();
   }}>
   <button type='submit'>Start now</button>
   </form>
   </React.StrictMode>
);
function startUserExp() {
  document.body.style.display = "table";
  document.getElementById("root").style.display = "table-cell";
  document.getElementById("root").style.verticalAlign = "middle";
   root.render(
      <React.StrictMode>
        <CircularProgress size={120} style={{color:"#ff0077"}}></CircularProgress>
        <h1 style={{color:NamishMusic.BrandColor}}>Please wait...</h1>
      </React.StrictMode>
   );
   getData();
}

async function getData() {
  const docRef = doc(getFirestore(), "MusicLength", "MusicLength");
  const docSnap = await getDoc(docRef);
  const allMusicsLength = docSnap.data().length;
  document.body.style.display = "block";
  document.getElementById("root").style.display = "block";
  document.getElementById("root").style.verticalAlign = "block";
  let muss = "";
  for(let i = 1; i <= allMusicsLength; i++) {
    const docRef2 = doc(getFirestore(), "Musics", String(i));
    const docSnap2 = await getDoc(docRef2);
    const musicName = docSnap2.data().name;
    const author = docSnap2.data().author;
    root.render(
      <React.StrictMode>
        <h1 style={{color:NamishMusic.Black}}>NamishMusic</h1>
       <div id="MusicsContainer" style={{fontSize:"16px"}}>
         <h1>{musicName}</h1>
         <p>By {author}</p>
         <form onSubmit={function (e) {
            e.preventDefault();
            const sourceURL = docSnap2.data().src;
            const posterURL = docSnap2.data().poster;
            root.render(
                 <React.StrictMode>
                  <h1>NamishMusic</h1>
                 <img src={posterURL} width={365} height={365}></img>
                 <p>{author}</p>
                 </React.StrictMode>
            );
         }}>
           <button type='submit'>Listen It</button> 
          </form>
       </div>
      </React.StrictMode>
     );
  }
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
